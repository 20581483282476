import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-account-push-notifications"
    }}>{`Configure Account Push Notifications`}</h1>
    <hr></hr>
    <p>{`To configure account related push notification to your patron's device, follow these step-by-step instructions. `}<em parentName="p">{`Patron's need to opt in to receive any type of push notification from the app in order to receive them. If they are not opted in, push notifications will not be sent to their device`}</em>{`:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Select the Mobile App or Template you wish to send the notification from. `}</li>
      <li parentName="ol">{`Enable 'Notifications' under the Features section. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app or template. `}</li>
      <li parentName="ol">{`Open the app or template again. The 'Edit Notifications' button will now display next the to 'Notifications' toggle. Select this to open the Notifications configuration page. `}</li>
      <li parentName="ol">{`Select 'Edit account notification settings'. This action will load the Edit Account Notification Settings page. `}</li>
      <li parentName="ol">{`Set Account Notifications to 'Enabled'. `}</li>
      <li parentName="ol">{`Toggle 'Checkout Due Soon' if you wish to send the patron a notification that a checked out item is due soon. If enabled, you will need to set how many days before the item is due to send the notification. For example, if set to three, a notification will be sent out three days before the item needs to be returned. `}</li>
      <li parentName="ol">{`Toggle 'Checkout Overdue' if you wish to send the patron a notification that a checked out item is overdue. If enabled, you will need to set how long after the item is overdue to reminder the patron. For example, if set to three, a notification will be sent out each day, for three days, after the item was due. `}</li>
      <li parentName="ol">{`Toggle 'Holds / reservations ready for pickup' if you wish to send the patron a notification that an item they have on hold is ready for pickup. `}</li>
      <li parentName="ol">{`Toggle 'User / Patron Account Expiration' if you wish to send the patron a notification that their account is going to expire soon. If enabled, you will need to set how how many days before the account expires to send a notifation. For example, if set to three, a notification will be sent to the patron three days before their account expires. `}</li>
      <li parentName="ol">{`Set 'Account Check Time'. This is when the app will check your ILS for updates to send. `}</li>
      <li parentName="ol">{`Set when the notification(s) will be sent. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      